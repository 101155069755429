//import { Link } from 'react-router-dom';
import './SearchResultsPage.css';
import card1 from './assets/card1.jpg';
import './Cards.css';

function Cards() {

    return (
            <div class="cards-container">
                <div class="card-search">
                    <img class="image-inCard-search" src={card1} alt="Card" />
                    <div class="card-details">
                        <text>name</text>
                        <text>sale price</text> 
                        <text>best offer</text> 
                    </div>
                </div>
                <div class="card-search">
                    <img class="image-inCard-search" src={card1} alt="Card" />
                    <div class="card-details">
                        <text>name</text>
                        <text>sale price</text> 
                        <text>best offer</text> 
                    </div>
                </div>
                <div class="card-search">
                    <img class="image-inCard-search" src={card1} alt="Card" />
                    <div class="card-details">
                        <text>name</text>
                        <text>sale price</text> 
                        <text>best offer</text> 
                    </div>
                </div>
                <div class="card-search">
                    <img class="image-inCard-search" src={card1} alt="Card" />
                    <div class="card-details">
                        <text>name</text>
                        <text>sale price</text> 
                        <text>best offer</text> 
                    </div>
                </div>
                <div class="card-search">
                    <img class="image-inCard-search" src={card1} alt="Card" />
                    <div class="card-details">
                        <text>name</text>
                        <text>sale price</text> 
                        <text>best offer</text> 
                    </div>
                </div>
                <div class="card-search">
                    <img class="image-inCard-search" src={card1} alt="Card" />
                    <div class="card-details">
                        <text>name</text>
                        <text>sale price</text> 
                        <text>best offer</text> 
                    </div>
                </div>
                <div class="card-search">
                    <img class="image-inCard-search" src={card1} alt="Card" />
                    <div class="card-details">
                        <text>name</text>
                        <text>sale price</text> 
                        <text>best offer</text> 
                    </div>
                </div>
                <div class="card-search">
                    <img class="image-inCard-search" src={card1} alt="Card" />
                    <div class="card-details">
                        <text>name</text>
                        <text>sale price</text> 
                        <text>best offer</text> 
                    </div>
                </div>
                <div class="card-search">
                    <img class="image-inCard-search" src={card1} alt="Card" />
                    <div class="card-details">
                        <text>name</text>
                        <text>sale price</text> 
                        <text>best offer</text> 
                    </div>
                </div>
                <div class="card-search">
                    <img class="image-inCard-search" src={card1} alt="Card" />
                    <div class="card-details">
                        <text>name</text>
                        <text>sale price</text> 
                        <text>best offer</text> 
                    </div>
                </div>
                <div class="card-search">
                    <img class="image-inCard-search" src={card1} alt="Card" />
                    <div class="card-details">
                        <text>name</text>
                        <text>sale price</text> 
                        <text>best offer</text> 
                    </div>
                </div>
                <div class="card-search">
                    <img class="image-inCard-search" src={card1} alt="Card" />
                    <div class="card-details">
                        <text>name</text>
                        <text>sale price</text> 
                        <text>best offer</text> 
                    </div>
                </div>
            </div>
    );
}

export default Cards;