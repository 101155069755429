import React, { useState, useEffect} from 'react';
//import { Link } from 'react-router-dom';
import './SearchResultsPage.css';
import { useNavigate } from 'react-router-dom';
import Cards from './Cards';

let ebayScriptLoaded = false;

function SearchResultsPage({ query, setQuery }) {


    // ✅ Load eBay script dynamically only ONCE
    useEffect(() => {
        const scriptUrl = "https://epnt.ebay.com/static/epn-smart-tools.js";

        if (!ebayScriptLoaded && !document.querySelector(`script[src="${scriptUrl}"]`)) {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.async = true;

            script.onerror = () => {
                console.error("Failed to load eBay script.");
            };

            document.body.appendChild(script);

            script.onload = () => {
                ebayScriptLoaded = true; // ✅ Mark script as loaded
                if (window._epn) {
                    window._epn(); // ✅ Initialize eBay Smart Tools
                }
            };
        }
    }, []);

    const [inputValue, setInputValue] = useState(query); // Local state to handle input
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        setInputValue(event.target.value); // Update local input state
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setQuery(inputValue); // Update the global query on form submission
        navigate('/results'); // Stay on the same page
    };

    return (
        <div>
            <div class="container-search"> {/*row to show icon/search/filters*/}
                <div class="icon-container">
                    icon
                </div>
                <div>
                    <form class="search-div" onSubmit={handleSubmit}>
                    <input 
                        class="search-bar-search"
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder={query}
                    />
                    </form>
                </div>
                <div>
                    <select class="filter1-properties-search" id="result-type" name="result-type">
                        <option value="sold">Sold</option>
                    </select>
                    <select class="filter2-properties-search" id="category" name="category">
                        <option value="Cards">Cards</option>
                    </select>
                    <select class="filter3-properties-search"  id="sort" name="sort">
                        <option value="high">Highest Price</option>
                    </select>
                </div>
            </div>

            <div class="splitContainer">
                <div class="row-under-search">
                {query ? (
                    <div>
                        <p>Showing results for: <strong>{query}</strong></p>
                        {/* Add logic to display actual search results here */}
                    <div class="results-view">
                    <Cards />
        
                    </div>
                </div>
                ) : (
                    <p>No search term provided.</p>
                )}
                </div>

                <div class="rightSide">
                    <div class="charts">
                        <p>Placeholder for charts</p>
                    </div>
                    <ins class="epn-placement" data-config-id="0009b7ca96a5275c49d1a549"></ins>
                </div>
            </div>

        </div>
    );
}

export default SearchResultsPage;