import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';


function LandingPage({ setQuery }) {
    const [inputValue, setInputValue] = useState('');
    const navigate = useNavigate(); // Now inside router context

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (inputValue.trim()) {
            setQuery(inputValue); // Update the search query in the state
            navigate('/results'); // Navigate to the results page
        }
    };

    return (
        <div class="container">
            <h1 class="header">Get Comps</h1>
            <h2 class="subHeader">Search Ebay, make an informed decision.</h2> 
            <form class="search-div" onSubmit={handleSubmit}>
                <input 
                    class="search-bar"
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Search..."
                />
            </form>
            <div>
                <select class="filter1-properties" id="result-type" name="result-type">
                    <option value="sold">Sold</option>
                </select>
                <select class="filter2-properties" id="category" name="category">
                    <option value="Cards">Cards</option>
                </select>
                <select class="filter3-properties"  id="sort" name="sort">
                    <option value="high">Highest Price</option>
                </select>
            </div>
        </div>
    );
}

export default LandingPage;